<template>
  <div>
    <van-form ref="Form" v-bind="{ ...$attrs, ...comProps }" :model="form">
      <van-row gutter="20">
        <template v-for="item in formItems()">
          <slot :name="`${fmCode}_${item.prop}`" :form="form" :config="item">
            <div v-if="item.component === 'HUpload'" :key="item.prop">
              <div class="h-flex h-flex-wrap h-col-center">
                <div class="h-font-md h-p-l-5">{{ item.label }}</div>
                <span v-if="item.desc" class="h-font-sm h-text-secondary-color">{{ item.desc }}</span>
              </div>
              <div class="h-p-5">
                <van-uploader v-model="form[item.prop]" :multiple="item.multiple" :max-count="item.limitNumber" :readonly="true" :deletable="false" :show-upload="false">
                  <template #preview-cover="item">
                    <div v-if="isPdf(item)">
                      <PdfPopupView :title="item.fileName" :pdfUrl="item.filePath">
                        <van-icon name="/images/upload/pdf.png" size="100%" />
                      </PdfPopupView>
                    </div>
                  </template>
                </van-uploader>
              </div>
            </div>
            <template v-else-if="item.component === 'HSlot'">
              <div :key="item.prop + 1">
                <slot :name="`${fmCode}${item.prop}Slot`" :form="form" :formContext="formContext" />
              </div>
            </template>
            <van-field v-else :key="item.prop" :label="item.label">
              <template #input>
                <div v-html="item.filters ? formatFilter(form[item.prop], item.filters) : form[item.prop]" />
              </template>
              <template #right-icon>
                <span v-if="item.append">{{ item.append }}</span
                ><slot name="right-icon"></slot>
              </template>
            </van-field>
          </slot>
        </template>
      </van-row>
    </van-form>
  </div>
</template>
<script>
import formatFilter from "@/utils/format";
import PdfPopupView from "@/components/Pdf/pdfPopup";
export default {
  name: "HYFormView",
  inheritAttrs: false,
  components: { PdfPopupView },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageLayout: {
      type: Array,
      default: () => {
        return {};
      }
    },
    pageLayoutMap: {
      type: Object,
      default: () => {
        return {};
      }
    },
    productFormItem: {
      type: Object,
      default: () => {
        return {};
      }
    },
    PageScript: {
      type: Function,
      default: null
    }
  },
  props: {
    fmCode: {
      type: String,
      default: ''
    },
    form: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    detailForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.config.formItems.forEach(formItem => {
          if (formItem.val === newVal[formItem.prop]) {
            return true;
          }
          formItem.val = newVal[formItem.prop];
        });
      },
      deep: true
    }
  },
  data() {
    return {
      formContext: this
    };
  },
  computed: {
    comProps() {
      return Object.assign(
        {
          inline: false,
          inputAlign: "right",
          errorMessageAlign: "right",
          showError: false,
          labelWidth: "60px",
          scrollToError: true,
          show: true,
          disabled: false,
          size: "mini",
          label: "",
          formItems: []
        },
        this.config
      );
    },
    pageScriptObj() {
      return new this.PageScript();
    }
  },
  created() {
    this.PageScript.prototype.pageContext = this.pageContext;
    this.PageScript.prototype.pageForm = this.pageForm;
    this.PageScript.prototype.pageLayout = this.pageLayout;
    this.PageScript.prototype.pageLayoutMap = this.pageLayoutMap;
    this.PageScript.prototype.detailForm = this.detailForm;
    this.PageScript.prototype.productFormItem = this.productFormItem;
    // this.init()
  },
  methods: {
    formatFilter,
    init() {
      // 初始化 model
      this.initForm();
      this.initWatch();
    },
    initForm() {
      this.formItems().forEach(formItem => {
        if (!formItem.prop) {
          return false;
        }
        if (this.form[formItem.prop]) {
          formItem.val = this.form[formItem.prop];
          return false;
        }
        this.$set(this.form, formItem.prop, formItem.val || null);
      });
    },
    initWatch() {
      this.PageScript.prototype.pageContext = this.pageContext;
      this.PageScript.prototype.pageForm = this.pageForm;
      this.PageScript.prototype.pageLayout = this.pageLayout;
      this.PageScript.prototype.pageLayoutMap = this.pageLayoutMap;
      this.PageScript.prototype.productFormItem = this.productFormItem;

      this.config.formItems.forEach(formItem => {
        if (!formItem.prop) {
          return false;
        }
        if (!formItem.watch) {
          return false;
        }
        const watchThat = new this.PageScript();
        watchThat.form = this.form;
        watchThat.ctx = this;
        this.$watch(`form.${formItem.prop}`, newVal => {
          watchThat.val = newVal;
          // eslint-disable-next-line no-new-func
          const fun = new Function("pageCtx", "ctx", "val", `${formItem.watch}`);

          fun.call(watchThat, this.pageContext, this, newVal);
        });
      });
    },
    isPdf(fileItem) {
      if (fileItem.fileType === "application/pdf") {
        return true;
      }
      if (!fileItem.fileName) {
        return false;
      }
      const suffix = fileItem.fileName.substring(fileItem.fileName.lastIndexOf(".") + 1);
      if (".pdf".indexOf(suffix) !== -1) {
        return true;
      }
      return false;
    },
    formItems() {
      return this.config.formItems.filter(item => {
        // eslint-disable-next-line no-unused-vars
        const pageForm = this.pageForm;
        // eslint-disable-next-line no-unused-vars
        const form = this.form;
        // eslint-disable-next-line no-unused-vars
        const pageScript = this.pageScriptObj;
        // eslint-disable-next-line no-eval
        return eval(`({ pageForm, form, pageScript }) => { 
          return ${item.view} 
        }`)({ pageForm, form, pageScript });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.uploader {
  // display: inline-block;
  text-align: center;
  .uploader-label {
    font-size: 12px;
    color: #b7b7b7;
    padding: 2px;
    span {
      color: #ee0a24;
    }
  }
}
::v-deep .van-uploader__preview-cover {
  background: #f7f8fa;
  padding-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
